window.addEventListener("DOMContentLoaded", () => {

    declare_event_on_filter_products();

});

function declare_event_on_filter_products(container) {

    if (container) {

        const filter_category = container.querySelectorAll('.filters_category')[0];

        let filters = filter_category.querySelectorAll('a');

        filters.forEach((filter) => {

            let cat_id = filter.getAttribute("data-id");

            filter.addEventListener('click', function () {
                if (filter.classList.contains('actif')) {
                    filter_by_cat("", container);
                    filter.classList.remove('actif')
                } else {
                    filter_by_cat(cat_id, container);

                    filters.forEach((filter) => {
                        filter.classList.remove('actif');
                    })
                    filter.classList.add('actif');
                }
            })
        });

    } else {

        const filters_category = document.querySelectorAll('.wp-block-starter-latest-events .filters_category');

        if (filters_category.length != 0) {
            if (filters_category.length > 1) {

                filters_category.forEach((filter_category) => {

                    const container = filter_category.closest('.wp-block-starter-latest-events');

                    let filters = filter_category.querySelectorAll('a');

                    filters.forEach((filter) => {

                        let cat_id = filter.getAttribute("data-id");

                        filter.addEventListener('click', function () {
                            if (filter.classList.contains('actif')) {
                                filter_by_cat("", container);
                                filter.classList.remove('actif')
                            } else {
                                filter_by_cat(cat_id, container);

                                filters.forEach((filter) => {
                                    filter.classList.remove('actif');
                                })
                                filter.classList.add('actif');
                            }
                        })
                    });
                });
            } else {
                const container = filters_category[0].closest('.wp-block-starter-latest-events');

                let filters = filters_category[0].querySelectorAll('a');

                filters.forEach((filter) => {

                    let cat_id = filter.getAttribute("data-id");

                    filter.addEventListener('click', function () {
                        if (filter.classList.contains('actif')) {
                            filter_by_cat("", container);
                            filter.classList.remove('actif')
                        } else {
                            filter_by_cat(cat_id, container);
                            filters.forEach((filter) => {
                                filter.classList.remove('actif');
                            })
                            filter.classList.add('actif');
                        }
                    })
                });
            }
        }
    }
}

function filter_by_cat(cat_id, container) {
    let posts_per_page = container.getAttribute('data-post_per_page');

    container.setAttribute('data-id', cat_id);
    container.style.opacity = "0.6";

    //Pagination ou loadmore ou rien

    let _data = {
        action: 'filter_cat_evenements',
        posts_per_page: posts_per_page,
        cat: cat_id,
    };

    fetch(ajaxurl_events['ajax_url'], {
        method: 'POST',
        body: (new URLSearchParams(_data)).toString(),
        headers: {
            'Content-type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        if (response.ok) {
            response.text().then(function (response) {
                container.innerHTML = response;

                declare_event_on_filter_products(container);
                container.style.opacity = "1";

            });
        } else {
            container.append(('<p> Une erreur est survenue, veuillez recharger la page</p>'))
        }
    });
}


